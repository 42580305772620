import * as React from "react";
import { navigate } from "gatsby";
import GoBack from "./goback";
import Button from "@material-ui/core/Button";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  container,
  heading,
  navLinks,
  navLinkItem,
  navLinkText,
} from "./layout.module.css";
import { body, bodydark } from "./global.css";

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={container}>
      <title>
        {pageTitle} | {data.site.siteMetadata.title}
      </title>
      <nav>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <div className={navLinkText}>
              <Button
                color="primary"
                startIcon={<FontAwesomeIcon icon={faAngleLeft} />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </li>
        </ul>
      </nav>
      <main>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
    </div>
  );
};

export default Layout;
